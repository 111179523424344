import React, { Component, RefObject } from "react";
require("@/styles/logoAudioVisualizer.less");
import { connect } from "react-redux";
import { RootState } from "../store/store";
import { RADIO_STATE } from "../store/liveRadio/action";
import { LiveRadioContext } from "./LiveAudioWrapper";
import { SizeMeProps, withSize } from "react-sizeme";

interface LogoAudioVisState {
  // playing: boolean;
  meterNum: number;
}

interface LogoAudioVisProps {
  pageVisible: boolean;
  playing: boolean;
  barColor: string;
  className?: string;
  onClick?: (e) => void;
}

/**
 * @todo prefer reduced motion
 */
class LogoAudioVisualizer extends Component<
  LogoAudioVisProps & SizeMeProps,
  LogoAudioVisState
> {
  meters = [];
  analyser: AnalyserNode = null;

  fadingAnimation = null;

  boundRenderFrame = null;
  currentAnimationFrame = null;

  mainVizRef: RefObject<HTMLDivElement> = React.createRef();

  state = {
    meterNum: 100,
  };

  constructor(props) {
    super(props);

    this.boundRenderFrame = this.renderFrame.bind(this);
    this.meters = [];
    for (let i = 0; i < this.state.meterNum; i++) {
      this.meters.push(React.createRef());
    }
  }

  componentDidMount() {
    if (this.props.playing) {
      this.runVisualization(); /*  */
    } else {
      this.stopVisualization();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.playing && !prevProps.playing) {
      // playing when wasn't playing before
      this.runVisualization();
    }

    if (!this.props.playing && prevProps.playing) {
      this.stopVisualization();
    }

    if (this.state.meterNum !== prevState.meterNum) {
      this.meters = [];
      for (let i = 0; i < this.state.meterNum; i++) {
        this.meters.push(React.createRef());
      }
    }
  }

  componentWillUnmount() {
    this.meters = null;

    if (this.boundRenderFrame != null) {
      cancelAnimationFrame(this.boundRenderFrame);
    }
  }

  runVisualization() {
    if (this.meters != null) {
      this.meters.forEach((meter) => {
        if (meter.current) {
          meter.current.className = meter.current.className
            .split(" ")
            .filter((clazz) => clazz !== "fading-meter")
            .join(" ");
        }
      });
    }

    if (this.currentAnimationFrame == null) {
      this.currentAnimationFrame = requestAnimationFrame(this.boundRenderFrame);
    }
  }

  stopVisualization() {
    cancelAnimationFrame(this.currentAnimationFrame);
    this.currentAnimationFrame = null;

    if (this.meters == null) {
      return;
    }
    this.meters.forEach((meter) => {
      if (meter.current) {
        meter.current.className = [
          "fading-meter",
          ...meter.current.className.split(" "),
        ].join(" ");
      }
    });
  }

  renderFrame() {
    if (!this.props.playing) {
      return;
    }

    const maxHeight = 90;
    const minHeight = 50;

    // loop
    if (this.meters != null && this.analyser != null) {
      const array = new Uint8Array(this.analyser.frequencyBinCount);

      this.analyser.getByteFrequencyData(array);

      const step = Math.round(array.length / this.meters.length); //sample limited data from the total array

      if (array.length < this.meters.length) {
        this.setState({
          meterNum: array.length,
        });
        return;
      }

      for (let i = 0; i < this.meters.length; i++) {
        const value = array[i * step];

        if (this?.meters[i]?.current == null) {
          continue;
        }

        // const rgbValue = Math.max(0, (255 - value) / 4);
        // const hslValue = Math.round((value / 255) * 360);
        this.meters[i].current.style.backgroundColor = this.props.barColor; // `hsl(${hslValue}, 89%, 50%)`;

        this.meters[i].current.style.opacity = value / 255 / 2 + 0.5;

        this.meters[i].current.style.height =
          (value / 255) * (maxHeight - minHeight) + minHeight + "%";
      }
    }

    this.currentAnimationFrame = requestAnimationFrame(this.boundRenderFrame);
  }

  render() {
    const width = this.props?.size?.width || 150;

    const rectangles = [];
    const LENGTH = this.state.meterNum;
    for (let i = 0; i < LENGTH; i++) {
      // make the rectangle width a portion of the circumference
      rectangles.push(
        <div
          className="vis-rect"
          style={{
            transform: "rotate(" + (360 / LENGTH) * i + "deg)",
            width: (Math.PI * width) / this.state.meterNum,
          }}
          key={"rect-" + i}
          ref={this.meters[i]}
        ></div>
      );
    }

    return (
      // <MediaQuery maxDeviceWidth={1024}>
      <LiveRadioContext.Consumer>
        {(audioWrapperObj) => {
          // is browser
          if (typeof window !== "undefined") {
            this.analyser = audioWrapperObj.analyser;
          }
          return (
            <div
              draggable={false}
              className={"visualizer " + (this.props.className || "")}
              ref={this.mainVizRef}
              onClick={this.props.onClick}
            >
              <img src="/assets/radioListen.png" draggable={false} />
              {rectangles}
            </div>
          );
        }}
      </LiveRadioContext.Consumer>
      // </MediaQuery>
    );
  }
}

const mapState = (state: RootState) => ({
  playing: state.liveRadio.state === RADIO_STATE.PLAYING,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(withSize()(LogoAudioVisualizer));

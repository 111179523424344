import React, { useCallback } from "react";

import Layout, { LAYOUT_QUERY } from "../components/Layout";
import _merge from "lodash/merge";

import LogoAudioVisualizer from "../components/LogoAudioVisualizer";
import { RootState } from "../store/store";
import {
  pauseRadio,
  playRadio,
  RADIO_FEED_STATE,
  RADIO_STATE,
  setRadioFeedState,
} from "../store/liveRadio/action";
import { connect } from "react-redux";

import { ParallaxProvider } from "react-scroll-parallax";
import { AboutContent } from "../components/AboutContent";

import { compose } from "redux";

import { StrapiImage } from "../typings/strapi";

import { fetchAPI } from "../utils/api/api";
import markdownToHtml from "../utils/markdownToHtml";

require("@/styles/index.less");
import { GRAPHQL_STRAPI_IMAGE } from "../components/StrapiProgressiveImage";
import { GetStaticProps } from "next";
import IndexBanner from "@/components/IndexBanner";
import dynamic from "next/dynamic";

const SocialMediaIcons = dynamic(
  () => import("../components/SocialMediaIcons")
);

const INDEX_PAGE_QUERY = `
query GetHomePage {
	homePage {
    Title,
    MidText,
    DayBackgroundMedia {
      ${GRAPHQL_STRAPI_IMAGE}
    },
    NightBackgroundMedia {
      ${GRAPHQL_STRAPI_IMAGE}
    },
    BarVisualizerColor
  },
  configuration{
    SocialMediaLinks {
      TwitterLink,
      InstagramLink,
      YoutubeLink,
      FacebookLink
    }
  },
  aboutPage {
    Content
  }
  ${LAYOUT_QUERY}
}
`;

interface IndexGraphQLData {
  homePage: {
    Title: string;
    MidText: string;
    DayBackgroundMedia: StrapiImage;
    NightBackgroundMedia: StrapiImage;
    BarVisualizerColor: string;
  };
  configuration: {
    SocialMediaLinks: {
      TwitterLink: string;
      FacebookLink: string;
      YoutubeLink: string;
      InstagramLink: string;
    };
  };
}
const IndexPage = ({
  playing,
  pauseRadio,
  playRadio,
  setRadioFeedState,

  graphQLData,
}) => {
  const data = graphQLData;

  const { FacebookLink, YoutubeLink, TwitterLink, InstagramLink } =
    data?.configuration?.SocialMediaLinks || {};

  const scroll = useCallback((node) => {
    if (node != null && /\/about\/?$/.test(location.href)) {
      window.scrollTo({
        behavior: "smooth",
        top: node.offsetTop,
      });
    }
  }, []);

  const togglePlaying = () => {
    if (playing) {
      pauseRadio();
    } else {
      playRadio();
      setRadioFeedState(RADIO_FEED_STATE.SHOWN);
    }
  };

  // if (loading || !data) return <LoadingLayout />;
  // if (!_isEmpty(errors)) return <p>Error: {JSON.stringify(errors)}</p>;

  const curHour = new Date().getHours();

  // day time is between 6am and 6pm
  const isDayTime = curHour > 6 && curHour < 18;

  const dayBackground: StrapiImage = data?.homePage?.DayBackgroundMedia;
  const nightBackground: StrapiImage = data?.homePage?.NightBackgroundMedia;
  const backgroundMedia: StrapiImage =
    (isDayTime ? dayBackground : nightBackground) ||
    dayBackground ||
    nightBackground;

  return (
    <ParallaxProvider>
      <Layout data={data} title={data?.homePage?.Title}>
        <IndexBanner image={backgroundMedia} />
        <div className="center-box">
          <LogoAudioVisualizer
            className={"audio-viz"}
            onClick={togglePlaying}
            barColor={data?.homePage?.BarVisualizerColor || "black"}
          />
          <div className="mid-text">
            <div
              dangerouslySetInnerHTML={{ __html: data?.homePage?.MidText }}
            ></div>
            <div className="mid-social-media">
              <SocialMediaIcons
                facebookLink={FacebookLink}
                twitterLink={TwitterLink}
                youtubeLink={YoutubeLink}
                instagramLink={InstagramLink}
              />
            </div>
          </div>
        </div>

        {/* <LogoAudioVisualizer />
          <LogoAudioVisualizer />
          <LogoAudioVisualizer /> */}
        {/* <p>Test</p> */}

        <div className="ocean-container">
          <div className="ocean">
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </div>

        <div className="belowFold" ref={scroll}>
          <AboutContent data={data} />
        </div>
      </Layout>
    </ParallaxProvider>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const data = await fetchAPI(INDEX_PAGE_QUERY);

  return _merge(
    {
      props: {
        graphQLData: data,
      },
      revalidate: 1,
    },
    {
      props: {
        graphQLData: {
          aboutPage: {
            Content: await markdownToHtml(data?.aboutPage?.Content),
          },
          homePage: {
            MidText: await markdownToHtml(data?.homePage?.MidText),
          },
        },
      },
    }
  );
};

const mapState = (state: RootState) => ({
  playing: state.liveRadio.state === RADIO_STATE.PLAYING,
});

const mapDispatch = {
  playRadio,
  pauseRadio,
  setRadioFeedState,
};

const connector = connect(mapState, mapDispatch);

export default compose(connector)(IndexPage);

import dynamic from "next/dynamic";
import React from "react";
import ProgressiveImage from "react-progressive-image";

require("@/styles/indexBanner.less");
import { StrapiImage } from "typings/strapi";

const ParallaxBanner = dynamic(
  async () => (await import("react-scroll-parallax")).ParallaxBanner,
  { ssr: false }
);

export const IndexBanner: React.FC<{ image: StrapiImage }> = ({ image }) => {
  return (
    <ProgressiveImage
      src={
        image != null
          ? image?.formats["1080p"]?.url || image?.formats["orig"]?.url
          : image?.formats["orig"]?.url
      }
      placeholder={
        image?.formats["qlif"]?.url ||
        image?.formats["thumbnail"]?.url ||
        image?.formats[Object.keys(image?.formats)[0]]?.url
      }
    >
      {(src, loading) => (
        <ParallaxBanner
          layers={[
            {
              image: src || "/assets/durham-cathedral-bg.jpg",
              amount: 0.2,
            },
          ]}
          style={{
            height: "100vh",
            filter: loading ? "blur(5px)" : "none",
          }}
        />
      )}
    </ProgressiveImage>
  );
};

export default IndexBanner;
